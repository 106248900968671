import { store } from '../redux/store';
import { storeCartItems } from '../redux/reducers/transactionCart';
import { getTransactionCartItems } from '../api/transactionCartApi';
import { showToast } from './toast.service';
import { toastMessages } from '../constants/errorMessages';

export const fetchTransactionCartItems = async (userId: number, accountId?: number) => {
  try {
    const { data: cartItems } = await getTransactionCartItems({ userId, accountId });
    store.dispatch(storeCartItems(cartItems));
  } catch (error: any) {
    showToast.error(toastMessages.SOMETHING_WENT_WRONG);
  }
};
