import { AxiosResponse } from 'axios';
import { accountContextGet, appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import {
  CardPaymentDetails,
  PaymentTransaction,
  StandardParams,
  PaymentDetails,
  AchPaymentDetails,
  ODataParams,
  BillingAmount,
} from '../types/interfaces';
import { PAYMENT_TRANSACTION_API_ENDPOINTS } from '../constants/apiEndpoints';
import { APP_PATHS } from '../constants/routePaths';

export const getPaymentTransactions = (params?: StandardParams): Promise<AxiosResponse<PaymentTransaction[], any>> => {
  return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_TRANSACTION, params);
};

export const getPaymentTransactionByTransactionId = (id: number): Promise<AxiosResponse<PaymentTransaction, any>> => {
  return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_TRANSACTION_ + id);
};

export const getPaymentTransactionsOdata = (params?: ODataParams): Promise<AxiosResponse<PaymentTransaction[], any>> => {
  return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_TRANSACTION_ODATA, params);
};

export const addPaymentDetails = (
  accountId: number,
  paymentData: CardPaymentDetails | AchPaymentDetails,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPost(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_DETAILS, paymentData, { accountId: accountId });
};

export const updatePaymentDetails = (
  accountId: number,
  paymentData: CardPaymentDetails | AchPaymentDetails,
): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_DETAILS, paymentData, { accountId: accountId });
};

export const getPaymentDetailsByAccountId = (accountId: number): Promise<AxiosResponse<PaymentDetails, any>> => {
  if (window.location.href.includes(APP_PATHS.USER_PROFILE) || window.location.href.includes(APP_PATHS.TRANSACTION_CART)) {
    return accountContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_DETAILS, { accountId: accountId });
  } else {
    return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENT_DETAILS, { accountId: accountId });
  }
};

export const getSubscriptionStatus = (accountId: number): Promise<AxiosResponse<boolean, any>> => {
  return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.USER_SUBSCRIBED, { accountId: accountId });
};

export const getOutstandingBalance = (accountId: number): Promise<AxiosResponse<BillingAmount, any>> => {
  if (window.location.href.includes(APP_PATHS.USER_PROFILE) || window.location.href.includes(APP_PATHS.TRANSACTION_CART)) {
    return accountContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.GET_OUTSTANDING_BALANCE, { accountId: accountId });
  } else {
    return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.GET_OUTSTANDING_BALANCE, { accountId: accountId });
  }
};

export const clearOutstandingBalance = (accountId: number): Promise<AxiosResponse<any>> => {
  return appContextPut(
    PAYMENT_TRANSACTION_API_ENDPOINTS.SUBSCRIPTION_ACCOUNTS_ + accountId + PAYMENT_TRANSACTION_API_ENDPOINTS.PAYMENTS,
    {},
  );
};

export const clearOutstandingBalanceByCash = (accountId: number, notes: string): Promise<AxiosResponse<any>> => {
  return appContextPost(
    PAYMENT_TRANSACTION_API_ENDPOINTS.SUBSCRIPTION_ACCOUNTS_ + accountId + PAYMENT_TRANSACTION_API_ENDPOINTS.CASH_PAYMENTS,
    {},
    { notes: notes },
  );
};

export const makeInstantPayment = (
  accountId: number,
  paymentData: CardPaymentDetails | AchPaymentDetails,
): Promise<AxiosResponse<any>> => {
  return appContextPut(
    PAYMENT_TRANSACTION_API_ENDPOINTS.SUBSCRIPTION_ACCOUNTS_ + accountId + PAYMENT_TRANSACTION_API_ENDPOINTS.INSTANT_PAYMENT,
    paymentData,
  );
};

export const getQuickBooksLateFee = (accountId: number): Promise<AxiosResponse<number, any>> => {
  return appContextGet(PAYMENT_TRANSACTION_API_ENDPOINTS.GET_QB_LATE_FEE + accountId);
};
