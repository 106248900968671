import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { Loader } from '../../components';
import AccountInfo from '../../components/account-info/AccountInfo';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import AddPaymentMethodModal, { initialPaymentState } from '../../components/payment-method-modal/AddPaymentMethodModal';
import CatalogSell from './dashboard-components/CatalogSell';
import InventoryGraphics from '../../components/account-info/InventoryGraphics';
import NotificationBanner from '../../components/notificationBanner/NotificationBanner';
import PagedTable from '../../components/sorted-table/PagedTable';
import SortedTable from '../../components/sorted-table/SortedTable';
import { centsToDollars } from '../../services/account.service';
import { doesAccountHasPendingPaymentTransaction, doesUserHaveSettingAccess } from '../../services/payment.service';
import { PermissionService } from '../../services/permission.service';
import { showToast } from '../../services/toast.service';
import { getAnimalOwnershipsByAccountId } from '../../api/animalOwnersApi';
import { getInventoryTransactions } from '../../api/inventoryTransactionsApi';
import { getPaymentDetailsByAccountId, getSubscriptionStatus } from '../../api/paymentGatewayApi';
import { AccountRoles, AppRoles, PaymentMethods, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums';
import { AnimalOwner } from '../../types/interfaces';
import { InventoryTransaction } from '../../types/interfaces/transaction.interfaces';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  NOTIFICATION_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();

  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccount = useAppSelector(state => state.activeAccount);
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const user = useAppSelector(state => state.user);
  const verified = useAppSelector(state => state.user.verified);

  const [addPaymentMethodModalVisible, setAddPaymentMethodModalVisible] = useState(false);
  const [inventory, setInventory] = useState<InventoryTransaction[]>([]);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>(initialPaymentState);
  const [pendingACHPayment, setPendingACHPayment] = useState<boolean>();
  const [pendingTransactions, setPendingTransactions] = useState<InventoryTransaction[]>([]);
  const [showPayNowButton, setShowPayNowButton] = useState<boolean>();
  const [userHaveAnimalOwnership, setUserHaveAnimalOwnership] = useState(false);
  const [userHaveSettingAccess, setUserHaveSettingAccess] = useState<boolean>();

  const includeInRequest = 'Specimen.SpecimenType,Specimen.Animal,TransactionLink.Account';
  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);

  const handleGetInventory = useCallback(async () => {
    if (accountId) {
      try {
        setLoadingItems(true);
        const { data } = await getInventoryTransactions({
          include: includeInRequest,
          filter: `accountId eq ${accountId};transactionType eq ${TransactionTypeEnum.Purchase}||transactionType eq ${TransactionTypeEnum.Collect}||transactionType eq ${TransactionTypeEnum.Deposit}`,
          limit: 5,
          sort: '^createdDatetime',
        });

        const inventoryTransactionData = (data as any).result;
        return inventoryTransactionData;
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingItems(false);
      }
    }
  }, [accountId]);

  const handleGetPendingTransactions = useCallback(async () => {
    if (accountId) {
      try {
        setLoadingTransactions(true);
        const { data } = await getInventoryTransactions({
          include: includeInRequest,
          filter: `accountId eq ${accountId};status eq ${TransactionStatusEnum.Pending};`,
          limit: 100,
        });

        const newPendingTransactions = (data as any).result;
        return newPendingTransactions;
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingTransactions(false);
      }
    }
  }, [accountId]);

  const handleAction = (id: number) => {
    navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY_VIEW + id, { state: { returnLocation: ROUTE_PATHS.APP_DASHBOARD } });
  };

  const handleSeeTransactions = () => {
    navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY);
  };

  const getSortedTable = (headers: string[], className: string) => {
    return (
      <div className={className}>
        <SortedTable
          sortIndex={className === 'mobile-view' ? 1 : 2}
          headers={headers.map(header => ({ displayName: header }))}
          data={inventory?.map((i: InventoryTransaction) => {
            return className === 'mobile-view'
              ? [
                  { content: i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-' },
                  { content: new Date(i?.createdDatetime).toLocaleDateString() },
                  { content: i?.changeQuantity },
                ]
              : [
                  { content: i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-' },
                  { content: i?.specimen?.specimenType?.name },
                  { content: new Date(i?.createdDatetime).toLocaleDateString() },
                  { content: i?.changeQuantity },
                  { content: i?.transactionType },
                  { content: i?.transactionLink?.account?.name },
                ];
          })}
          loading={loadingItems}
          noResultsMessage={LABEL_CONSTANTS.NO_INVENTORY}
        />
      </div>
    );
  };

  const getPendingTransactionTable = (headers: string[], className: string, maxHeight: number) => {
    return (
      <div className={className}>
        <PagedTable
          headers={headers.map(header => ({ displayName: header }))}
          sortBy="^createdDatetime"
          include={includeInRequest}
          filter={`accountId eq ${accountId};status eq ${TransactionStatusEnum.Pending};`}
          getData={getInventoryTransactions}
          height={maxHeight}
          buildRow={i => {
            return className === 'mobile-view'
              ? [
                  i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-',
                  i?.transactionType,
                  i?.changeQuantity,
                  <ActionMenu
                    actionButtons={[
                      { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => handleAction(i?.inventoryTransactionId) },
                    ]}
                  />,
                ]
              : [
                  i?.inventoryTransactionId,
                  i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-',
                  i?.transactionType,
                  i?.changeQuantity,
                  new Date(i?.createdDatetime).toLocaleDateString(),
                  i?.status,
                  <ActionMenu
                    actionButtons={[
                      { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => handleAction(i?.inventoryTransactionId) },
                    ]}
                  />,
                ];
          }}
        />
      </div>
    );
  };

  const checkPaymentValidity = () => {
    return activeAccount?.billingAmount?.outstandingBalanceCents > 0 || activeAccount?.billingAmount?.penaltyCents > 0;
  };

  const getPaymentDetails = async () => {
    if (accountId && paymentStatus && activeAccount?.billingEnabled) {
      try {
        const { data: isUserSubscribed } = await getSubscriptionStatus(accountId);
        if (isUserSubscribed) {
          const { data: paymentDetailsResponse } = await getPaymentDetailsByAccountId(accountId);
          let accountData =
            paymentDetailsResponse?.paymentMethod === PaymentMethods.CARD
              ? {
                  cardNumber: {
                    value: paymentDetailsResponse?.maskedCardNumber,
                    valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                  },
                  expiry: {
                    value: paymentDetailsResponse?.expiry,
                    valid: paymentDetailsResponse?.expiry?.length > 0 ? true : false,
                  },
                  accountNumber: { value: '', valid: false },
                  accountType: { value: '', name: '' },
                }
              : {
                  cardNumber: { value: '', valid: false },
                  expiry: { value: '', valid: false },
                  accountNumber: {
                    value: paymentDetailsResponse?.maskedCardNumber,
                    valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                  },
                  accountType: { value: paymentDetailsResponse?.accountType, name: paymentDetailsResponse?.accountType },
                };

          setPaymentInfo({
            paymentType: {
              value: paymentDetailsResponse?.paymentMethod,
              valid: paymentDetailsResponse?.paymentMethod ? true : false,
            },
            name: { value: paymentDetailsResponse?.name, valid: paymentDetailsResponse?.name?.length > 0 ? true : false },
            address: {
              value: paymentDetailsResponse?.address,
              valid: paymentDetailsResponse?.address?.length > 0 ? true : false,
            },
            city: { value: paymentDetailsResponse?.city, valid: paymentDetailsResponse?.city?.length > 0 ? true : false },
            region: { value: paymentDetailsResponse?.region, valid: paymentDetailsResponse?.region?.length > 0 ? true : false },
            postalCode: {
              value: paymentDetailsResponse?.postal,
              valid: paymentDetailsResponse?.postal?.length > 0 ? true : false,
            },
            country: {
              value: paymentDetailsResponse?.country,
              valid: paymentDetailsResponse?.country?.length > 0 ? true : false,
            },
            routingNumber: { value: '', valid: false },
            cvv: { value: '', valid: false },
            ...accountData,
          });
        }
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const getTotalAnimalOwnershipsForAccount = useCallback(async () => {
    if (accountId) {
      try {
        const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: `accountId eq ${accountId}` });
        setUserHaveAnimalOwnership(animalOwnership.length != 0);
      } catch (error: any) {
        throw new Error(error);
      }
    }
  }, [accountId]);

  useEffect(() => {
    getTotalAnimalOwnershipsForAccount();
  }, [getTotalAnimalOwnershipsForAccount]);

  useEffect(() => {
    permissionService.userHasSettingsPermission() && getPaymentDetails();
  }, [addPaymentMethodModalVisible, accountId]);

  useEffect(() => {
    if (accountId) {
      doesUserHaveSettingAccess(accountId, user?.userId)
        .then(response => {
          setUserHaveSettingAccess(response);
          checkPaymentValidity() && setShowPayNowButton(response);
        })
        .catch(error => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  }, [activeAccount]);

  useEffect(() => {
    handleGetInventory().then(i => {
      setInventory(i);
    });
  }, [handleGetInventory]);

  useEffect(() => {
    handleGetPendingTransactions().then(pending => {
      setPendingTransactions(pending);
    });
  }, [handleGetPendingTransactions]);

  useEffect(() => {
    if (accountId && permissionService.userHasSettingsPermission()) {
      doesAccountHasPendingPaymentTransaction()
        .then(response => {
          setPendingACHPayment(response);
        })
        .catch(error => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  }, [accountId]);

  const accountSettingsLink = (
    <Link to={ROUTE_PATHS.APP_ACCOUNT_SETTINGS} className="payment-details-link" state={{ activeTab: 2 }}>
      {PAGE_HEADER_CONSTANTS.SETTINGS}
    </Link>
  );

  const getNotificationMessage = () => {
    if (userHaveSettingAccess != undefined && userHaveSettingAccess) {
      return <p>To access all the features of this account, please go to {accountSettingsLink} and add your payment details.</p>;
    } else {
      return <p>{NOTIFICATION_CONSTANTS.ADD_PAYMENT_DETAILS}</p>;
    }
  };

  return (
    <div className="dashboard">
      {user?.accounts?.length > 0 && paymentStatus != undefined && !paymentStatus && (
        <>
          <div className="xs-notification-banner max-width">
            <NotificationBanner
              children={<div className="payment-details-not-found-notification">{getNotificationMessage()}</div>}
            />
          </div>
        </>
      )}
      {user?.accounts?.length > 0 &&
        paymentStatus != undefined &&
        paymentStatus &&
        (activeAccount?.billingAmount?.outstandingBalanceCents > 0 ? (
          <>
            <div className="xs-notification-banner max-width">
              {pendingACHPayment != undefined &&
                (!pendingACHPayment ? (
                  <NotificationBanner
                    children={
                      <div className="pay-now-notification">
                        <p className={`${!showPayNowButton ? 'full-width-pay-now-notification' : ''}`}>
                          {activeAccount?.billingAmount?.penaltyCents > 0 ? (
                            /* Here we need to add the quickbooks late fee in total fine amount in case we turn on the penalty */
                            <>
                              <b>{activeAccount?.name}</b> account has a balance due of{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.totalFineAmountCents)}</b>. (Includes{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.outstandingBalanceCents)}</b> outstanding balance,{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.lateFeeCents)}</b> late fee,{' '}
                              <b> ${centsToDollars(activeAccount?.billingAmount?.financeChargeCents)}</b> finance charge of{' '}
                              {activeAccount?.billingAmount?.financeChargeRate}%)
                            </>
                          ) : (
                            <>
                              <b>{activeAccount?.name}</b> account has a balance due of{' '}
                              <b>
                                $
                                {centsToDollars(
                                  activeAccount?.billingAmount?.totalFineAmountCents +
                                    (activeAccount?.billingAmount?.quickbooksLateFeeCents ?? 0),
                                )}
                              </b>
                              . Please pay the balance to avoid additional charges.
                            </>
                          )}
                        </p>
                        {showPayNowButton != undefined && showPayNowButton && (
                          <button
                            className="button green small pay-now-button"
                            type="button"
                            onClick={() => setAddPaymentMethodModalVisible(true)}>
                            {BUTTON_CONSTANTS.PAY_NOW}
                          </button>
                        )}
                      </div>
                    }
                  />
                ) : (
                  <>
                    <NotificationBanner
                      children={
                        <div className="payment-details-not-found-notification">
                          <p>{NOTIFICATION_CONSTANTS.PENDING_ACH_PAYMENT}</p>
                        </div>
                      }
                    />
                  </>
                ))}
            </div>
          </>
        ) : (
          activeAccount?.billingAmount?.outstandingBalanceCents <= 0 &&
          activeAccount?.locked && (
            <div className="xs-notification-banner max-width">
              <NotificationBanner
                children={
                  <div className="payment-details-not-found-notification">
                    <p>{NOTIFICATION_CONSTANTS.ACCOUNT_LOCKED}</p>
                  </div>
                }
              />
            </div>
          )
        ))}

      {accountId && addPaymentMethodModalVisible && (
        <AddPaymentMethodModal
          title={BUTTON_CONSTANTS.PAY_NOW}
          accountId={accountId}
          paymentDetails={paymentInfo}
          isOpen={addPaymentMethodModalVisible}
          setIsOpen={setAddPaymentMethodModalVisible}
          onclick={() => setAddPaymentMethodModalVisible(false)}
          buttonAction={'PayNow'}
        />
      )}

      {accountId !== undefined && (
        <>
          <div className="card max-width">
            <AccountInfo accountId={accountId} />
          </div>
          <div className="card max-width">
            <InventoryGraphics accountId={accountId} />
          </div>
        </>
      )}

      <div className="card full recently-acquired-inventory">
        <h2 className="inventory-header">{LABEL_CONSTANTS.ACQUIRED_INVENTORY}</h2>
        <div className="inventory-table">
          {getSortedTable(
            [TABLE_HEADER_CONSTANTS.ANIMAL, TABLE_HEADER_CONSTANTS.PURCHASE_DATE, TABLE_HEADER_CONSTANTS.QUANTITY],
            'mobile-view',
          )}
          {getSortedTable(
            [
              TABLE_HEADER_CONSTANTS.ANIMAL,
              TABLE_HEADER_CONSTANTS.ITEM_TYPE,
              TABLE_HEADER_CONSTANTS.ACQUIRED,
              TABLE_HEADER_CONSTANTS.QUANTITY,
              TABLE_HEADER_CONSTANTS.TRANSACTION_TYPE,
              TABLE_HEADER_CONSTANTS.SELLER,
            ],
            'desktop-view',
          )}
        </div>
      </div>

      <div className="card full pending-transactions">
        <h2 className="pending-transactions-header">{LABEL_CONSTANTS.PENDING_TRANSACTIONS}</h2>

        <div className="pending-transactions-table">
          {pendingTransactions && pendingTransactions.length > 0 ? (
            <>
              {getPendingTransactionTable(
                [TABLE_HEADER_CONSTANTS.ANIMAL, TABLE_HEADER_CONSTANTS.TYPE, TABLE_HEADER_CONSTANTS.CHANGED_QTY, ' '],
                'mobile-view',
                194,
              )}
              {getPendingTransactionTable(
                [
                  TABLE_HEADER_CONSTANTS.TRANSACTION_ID,
                  TABLE_HEADER_CONSTANTS.ANIMAL,
                  TABLE_HEADER_CONSTANTS.TYPE,
                  TABLE_HEADER_CONSTANTS.CHANGED_QTY,
                  TABLE_HEADER_CONSTANTS.DATE,
                  TABLE_HEADER_CONSTANTS.STATUS,
                  TABLE_HEADER_CONSTANTS.ACTION,
                ],
                'desktop-view',
                256,
              )}
            </>
          ) : loadingTransactions ? (
            <Loader loaderSize="medium" addedSpace simple />
          ) : (
            <h2 className="no-inventory-available">{LABEL_CONSTANTS.NO_PENDING_TRANSACTIONS}</h2>
          )}
        </div>
      </div>
      <button className="see-transactions button blue" onClick={handleSeeTransactions}>
        <p>{BUTTON_CONSTANTS.SEE_TRANSACTIONS}</p>
      </button>

      {userHaveAnimalOwnership && <CatalogSell />}

      <div className="card full animal-ownership">
        <h2 className="animal-ownership-header">{ANIMAL_CONSTANTS.ANIMAL_OWNERSHIP}</h2>
        <div className="animal-ownership-table">
          <PagedTable
            headers={[
              TABLE_HEADER_CONSTANTS.ANIMAL_CODE,
              TABLE_HEADER_CONSTANTS.ANIMAL_NAME,
              TABLE_HEADER_CONSTANTS.OWNERSHIP_PERCENTAGE,
            ].map(header => ({ displayName: header }))}
            height={254}
            filter={`accountId eq ${accountId}`}
            include="Animal"
            getData={params => {
              return getAnimalOwnershipsByAccountId(params);
            }}
            buildRow={(item: AnimalOwner) => {
              return [item?.animal?.code, item?.animal?.name, item?.ownershipPercentage];
            }}
            noResultsMessage={LABEL_CONSTANTS.ACCOUNT_HAS_NO_ANIMAL_OWNERSHIP}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
